import { Image } from '@graphcommerce/image'
import { RichText } from '@graphcommerce/graphcms-ui'
import whatsappIcon from '../../../images/whatsapp.png'
import emailIcon from '../../../images/email.png'
import {
  IconBlock,
  IconBlocks,
  iconChat,
  iconEmail,
  iconPhone,
  IconSvg,
} from '@graphcommerce/next-ui'
import { RowServiceOptionsFragment } from './RowServiceOptions.gql'

type RowServiceOptionsProps = RowServiceOptionsFragment

export function RowServiceOptions(props: RowServiceOptionsProps) {
  const { title, serviceOptions } = props

  return (
    <IconBlocks title={title}>
      {serviceOptions.map((serviceOption) => {
        const iconTitle = serviceOption.title.toLowerCase()

        return (
            <IconBlock
            key={serviceOption.title}
            href={serviceOption.url}
              title={serviceOption.title}
              icon={
                <>
                  {iconTitle === 'e-mail' && <Image
						src={emailIcon}
						alt={iconTitle}
						sx={(theme) => ({
							width: '26px',
							height: '26px',
						})}
					/>}
                  {iconTitle === 'phone' && <IconSvg src={iconPhone} size='large' />}
				  {iconTitle === 'whatsapp' && <Image
						src={whatsappIcon}
						alt={iconTitle}
						sx={(theme) => ({
							width: '26px',
							height: '26px',
						})}
					/>}
                  {iconTitle === 'chat' && <IconSvg src={iconChat} size='large' />}
                </>
              }
			  sx={(theme) => ({
				color: '#000000'
			  })}
            >
              {serviceOption.description ? 
				<RichText 
					{...serviceOption.description}
					sxRenderer={{
						paragraph: {
						  height: { xs: '120px', md: '85px' },
						  lineHeight: '20px',
						  color: '#000000',
						},
					}}
				/> 
			  : undefined}
            </IconBlock>
        )
      })}
    </IconBlocks>
  )
}
